<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add New" }} Job
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="pest-form">
          <v-text-field
            label="Price *"
            v-model="fields.price"
            type="number"
            step="0.01"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>

          <v-text-field
            label="Pest Name *"
            v-model="fields.pest_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('pest_name')"
            :error-messages="errors['pest_name']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="pest-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      fields: {
        price: null,
        pest_name: null,
      },

      errors: {},
    };
  },

  computed: {},

  methods: {
    open(order = null) {
      if (order !== null) {
        this.order = order;
        this.isEditing = true;
        this.fields.price = order.total;
        this.fields.pest_name = order.pest_name;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      this.fields.form_name = "price";

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.orderId = this.order.id;
      }

      this.$store
        .dispatch("graham/orders/saveOrder", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.errors = {};
      this.customer = {};
      this.fields = {
        pest_name: null,
        price: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
