<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>Order GPC{{ order.id }} - {{ order.name }}</h1>
        </v-col>
        <v-col cols="6" align="right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="order.source == 'Manual'"
                depressed
                class="mr-2"
                color="green green--text lighten-4"
                @click="sendToCustomerPrompt()"
                ><v-icon small>mdi-email</v-icon></v-btn
              >
            </template>
            <span>Send Email</span>
          </v-tooltip>
          <v-btn depressed :to="{ name: 'module-graham-orders' }"
            >Return to Orders</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="9">
          <v-row no-gutters>
            <v-col cols="12">
              <v-card outlined class="mb-6">
                <v-toolbar flat dark dense :color="appColor">
                  <v-toolbar-title>Pests</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="order.source == 'Manual'"
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        v-on="on"
                        @click="$refs.pestDialog.open(order)"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </v-toolbar>

                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th class="text-right">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="py-2">
                        <div class="text-subtitle-1 font-weight-bold">
                          {{ order.pest_name }}
                        </div>
                      </td>
                      <td class="py-2">
                        <div>
                          {{ order.description }}
                        </div>
                      </td>
                      <td class="text-right">
                        £{{ formatPrice(order.total) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>

            <v-row>
              <v-col cols="6">
                <v-card outlined class="mb-6">
                  <v-toolbar flat dark dense :color="appColor">
                    <v-toolbar-title>Payment</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-simple-table class="mb-5 table" divider="true">
                    <tbody>
                      <tr>
                        <td>Payment Intent</td>
                        <td>{{ order.payment_intent }}</td>
                      </tr>

                      <tr>
                        <td>Date</td>
                        <td>{{ formatDateTime(order.created_at, true) }}</td>
                      </tr>
                      <tr v-if="order.token && order.status != 'complete'">
                        <td>Payment Link</td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                v-on="on"
                                depressed
                                color="blue lighten-4 blue--text"
                                class="mr-2"
                                :href="getOrderUrl(order.token)"
                                target="_blank"
                              >
                                <v-icon small>mdi-monitor-dashboard</v-icon>
                              </v-btn>
                            </template>
                            <span>View Order Page</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>

                <v-card class="mb-6">
                  <v-toolbar flat dark dense :color="appColor">
                    <v-toolbar-title>Audit</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-divider></v-divider>

                  <v-data-table
                    :headers="tableHeaders"
                    :items="order.audits"
                    no-data-text="No Audit"
                    :items-per-page="-1"
                  >
                    <template v-slot:item.audit_date="{ item }">
                      {{ formatDateTime(item.audit_date, true) }}
                    </template>

                    <template v-slot:item.user="{ item }">
                      {{ item.user ? item.user.full_name : "Customer" }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card outlined class="mb-6">
                  <v-toolbar flat dark dense :color="appColor">
                    <v-toolbar-title>Other Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-simple-table class="mb-5 table" divider="true">
                    <tbody>
                      <tr>
                        <th>Source</th>
                        <td>{{ order.source }}</td>
                      </tr>
                      <tr>
                        <th>Location of pest on the property</th>
                        <td>{{ order.location_on_property }}</td>
                      </tr>
                      <tr>
                        <th>Have you used our services before?</th>
                        <td>{{ order.used_our_services ? "Yes" : "No" }}</td>
                      </tr>

                      <tr v-for="(input, name) in order.content" :key="name">
                        <th>{{ name }}</th>
                        <td
                          style="padding-top: 8px; padding-bottom: 8px"
                          v-html="input"
                        ></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>

                <v-card outlined class="mb-6">
                  <v-toolbar flat dark dense :color="appColor">
                    <v-toolbar-title>Job</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.jobDialog.open(order)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-toolbar>
                  <v-simple-table class="mb-5 table" divider="true">
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <td>{{ order.order_type }}</td>
                      </tr>
                      <tr>
                        <th>Service Tracker Reference</th>
                        <td>{{ order.tracker_ref_number }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Order Status"
            v-model="status"
            :items="statuses"
            :loading="statusLoading"
            hint="Update the Order Status here"
            outlined
            persistent-hint
            @change="statusChanged()"
          ></v-select>

          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Address</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.addressDialog.open(order)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <div>{{ order.name }}</div>
              <div>{{ order.address }}</div>

              <div>{{ order.town }}</div>
              <div>{{ order.city }}</div>

              <div>{{ order.postcode }}</div>

              <div class="mt-2">
                <strong>Email Address:</strong>&nbsp;
                <a :href="`mailto:${order.email}`">{{ order.email }}</a>
              </div>

              <div class="mt-2">
                <strong>Phone:</strong>&nbsp;
                <a :href="`tel:${order.phone}`">{{ order.phone }}</a>
              </div>
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-6" v-if="order.pest_picture">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Pest Picture</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <div>
                <v-img
                  :src="order.asset_urls.pest_picture"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                </v-img>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="sendToCustomer.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Send to Customer</v-card-title>
          <v-card-text
            >Are you sure you wish to send this to
            <strong>
              {{ order.name }}
              {{ order.email }}</strong
            >?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetSendToCustomer"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="sendToCustomer.loading"
              @click="sendToCustomerConfirmed"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <address-dialog ref="addressDialog" />
      <job-dialog ref="jobDialog" />
      <pest-dialog ref="pestDialog" />
    </v-container>
  </div>
</template>

<script>
import AddressDialog from "./components/AddressDialog.vue";
import JobDialog from "./components/JobDialog.vue";
import PestDialog from "./components/PestDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {
    AddressDialog,
    JobDialog,
    PestDialog,
  },
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Orders",
          disabled: false,
          exact: true,
          to: {
            name: "module-graham-orders",
          },
        },
        {
          text: "Individual Order",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user" },
        { text: "Activity", value: "audit_text" },
      ],
      statuses: [
        { text: "Generated", value: "generated" },
        { text: "Sent To Customer", value: "sent-to-customer" },
        { text: "Processing", value: "processing" },
        { text: "Pending Payment", value: "pending-payment" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Complete", value: "complete" },
      ],
      status: null,
      statusLoading: false,
      sendToCustomer: {
        errors: {},
        dialog: false,
        loading: false,
      },
    };
  },

  computed: {
    order() {
      return this.$store.state.graham.orders["order"];
    },
  },

  mounted() {
    this.status = this.order.status;
    if (this.order.content) {
      delete this.order.content["Checkbox"];
    }
  },

  methods: {
    formatPrice(value) {
      return parseFloat(value).toFixed(2);
    },
    getOrderUrl(unique_id) {
      return process.env.VUE_APP_GRAHAM_URL + "/order/" + unique_id;
    },
    sendToCustomerPrompt() {
      this.sendToCustomer.dialog = true;
    },
    resetSendToCustomer() {
      this.sendToCustomer.errors = null;

      this.sendToCustomer.dialog = false;

      this.sendToCustomer.loading = false;
    },
    sendToCustomerConfirmed() {
      const appId = this.$route.params.id;
      const orderId = this.$route.params.orderId;

      this.sendToCustomer.loading = true;

      this.$store
        .dispatch("graham/orders/sendToCustomer", {
          appId,
          orderId,
        })
        .then(() => {
          this.status = "sent-to-customer";

          this.resetSendToCustomer();
        })
        .catch((err) => {
          this.sendToCustomer.errors = err.response.data.errors;
          this.sendToCustomer.loading = false;
        });
    },
    statusChanged: function () {
      this.statusLoading = true;

      this.$store
        .dispatch("graham/orders/saveOrder", {
          appId: this.$route.params.id,
          orderId: this.$route.params.orderId,
          isEditing: true,
          fields: {
            status: this.status,
          },
        })
        .then(() => {
          this.statusLoading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Order Status Updated Successfully!",
            color: "success",
          });
        })
        .catch(() => (this.statusLoading = false));
    },
  },
};
</script>
