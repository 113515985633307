<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add New" }} Address
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="address-form">
          <v-text-field
            label="Customer Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Customer email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>

          <v-text-field
            label="Customer Address *"
            v-model="fields.address"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address')"
            :error-messages="errors['address']"
          ></v-text-field>
          <v-text-field
            label="Town"
            v-model="fields.town"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('town')"
            :error-messages="errors['town']"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.city"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('city')"
            :error-messages="errors['city']"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('postcode')"
            :error-messages="errors['postcode']"
          ></v-text-field>

          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="address-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      fields: {
        town: null,
        city: null,
        postcode: null,
        address: null,
        name: null,
        email: null,
        phone: null,
      },

      errors: {},
    };
  },

  computed: {},

  methods: {
    open(order = null) {
      if (order !== null) {
        this.order = order;
        this.isEditing = true;

        this.fields.town = order.town;
        this.fields.city = order.city;
        this.fields.postcode = order.postcode;
        this.fields.address = order.address;
        this.fields.name = order.name;
        this.fields.email = order.email;
        this.fields.phone = order.phone;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.orderId = this.order.id;
      }

      this.$store
        .dispatch("graham/orders/saveOrderAddress", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.errors = {};
      this.customer = {};
      this.fields = {
        order_type: null,
        price: null,
        town: null,
        city: null,
        postcode: null,
        address: null,
        name: null,
        email: null,
        description: null,
        phone: null,
        source: "Manual",
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
